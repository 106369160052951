.reset-password-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px; }

.form-group {
  margin-bottom: 15px; }

label {
  display: block;
  margin-bottom: 5px; }

input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px; }

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

button:hover {
  background-color: #0056b3; }

.error {
  color: red;
  font-size: 12px; }
