.transition {
  transition: transform ease 0.2s; }

.bodyLeft {
  transform: translateX(230px); }

.bodyRight {
  transform: translateX(-230px); }

.bodyFixed {
  position: fixed; }

.appContainer {
  width: 100%;
  left: 0;
  position: relative;
  transition: all 0.3s ease; }
