:root {
  --navBg: #f6f6f6; }

.headerColor {
  color: black; }

.navbar {
  background-color: var(--navBg);
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-style: none !important;
  z-index: 3 !important;
  padding: 0 20px 0 0 !important; }

.menu-bars {
  margin-left: 1.8rem;
  font-size: 1rem;
  z-index: 2 !important;
  color: black !important; }

.marginTop {
  margin-top: 5px !important; }

.nav-menu {
  color: var(--navBg);
  width: 228px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2 !important; }

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 2 !important; }

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 55px;
  z-index: 2 !important; }

.nav-text {
  display: flex;
  align-items: center;
  padding: 10px;
  color: black;
  transition: background-color 0.3s; }

.nav-text.active {
  background-color: #e0e0e0;
  /* Highlight color when active */ }

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 17px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  z-index: 2 !important; }

.nav-text:hover {
  background-color: #e0e0e0;
  transform: scale(1.05); }

.nav-menu-items {
  width: 100%;
  padding: 60px 0px 0px 0px;
  z-index: 2 !important;
  background-color: var(--navBg); }

.navbar-toggle {
  background-color: var(--navBg);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 30px;
  z-index: 2 !important; }

.span {
  margin-left: 6px;
  z-index: 2 !important; }

.iconColor {
  color: black !important; }
